const matchesFn =
  Element.prototype.matchesSelector ||
  Element.prototype.mozMatchesSelector ||
  Element.prototype.msMatchesSelector ||
  Element.prototype.oMatchesSelector ||
  Element.prototype.webkitMatchesSelector ||
  function(selector) {
    const allMatches = (this.document || this.ownerDocument).querySelectorAll(selector)
    allMatches.forEach(function(match) {
      if(match == this) {
        return true
      }
    })
  }

const closestFn = Element.prototype.closest || function(selector) {
  const document = this.document || this.ownerDocument
  const allMatching = document.querySelectorAll(selector)
  let element = this

  while(true) {
    if (Array.from(allMatching).includes(element)) { return element }
    element = element.parentElement
    if (!element || (element.nodeType !== 1)) { return; }
  }
}


export function matches (element, selector) {
  return matchesFn.apply(element, [selector])
}

export function closest (element, selector) {
  return closestFn.apply(element, [selector])
}
